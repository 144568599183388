.swiper-slide {
  width: max-content !important;
  max-width: 100% !important;
}

.swiper-pagination {
  bottom: 0px !important;
}

.swiper-button-prev {
  left: 4px !important;
  width: 33px !important;
  height: 33px !important;
}

.swiper-button-prev::after {
  font-size: 16px !important;
  font-weight: 700;
  color: #666666;
  background: white;
  padding: 8px;
  border-radius: 50%;
  width: 100%;
  text-align: center;
  border: 1px solid #EAEBEC;
  box-shadow: rgb(146 153 184 / 8%) 0px 4px 16px;
}

.swiper-button-next {
  right: 4px !important;
  width: 33px !important;
  height: 33px !important;
}

.swiper-button-next::after {
  font-size: 16px !important;
  font-weight: 700;
  color: #666666;
  background: white;
  padding: 8px;
  border-radius: 50%;
  width: 100%;
  text-align: center;
  border: 1px solid #EAEBEC;
  box-shadow: rgb(146 153 184 / 8%) 0px 4px 16px;
}