.rotatingText {
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  margin-bottom: 0;
  position: absolute;
  text-align: center;
}

.rotatingText:nth-of-type(1) {
  animation-name: rotate-last;
  animation-duration: 4s;
  animation-delay: 0.5s;
}

.rotatingText:nth-of-type(2) {
  animation-name: rotate-last;
  animation-duration: 4s;
  animation-delay: 4.5s;
}

.rotatingText:nth-of-type(3) {
  animation-name: rotate-last;
  animation-duration: 4s;
  animation-delay: 8.5s;
}

.rotatingText:nth-of-type(4) {
  animation-name: rotate-last;
  animation-duration: 4s;
  animation-delay: 12.5s;
}

.rotatingText:nth-of-type(5) {
  animation-name: rotate-last;
  animation-duration: 4s;
  animation-delay: 16.5s;
}

.rotatingText:nth-of-type(6) {
  animation-name: rotate-last;
  animation-duration: 4s;
  animation-delay: 20.5s;
}

.rotatingText:nth-of-type(7) {
  animation-name: rotate-last;
  animation-duration: 4s;
  animation-delay: 24.5s;
}

.rotatingText:nth-of-type(8) {
  animation-name: rotate-last;
  animation-duration: 4s;
  animation-delay: 28.5s;
}

.rotatingText:nth-of-type(9) {
  animation-name: rotate-last;
  animation-duration: 4s;
  animation-delay: 32.5s;
}

.rotatingText:nth-of-type(10) {
  animation-name: rotate-last;
  animation-duration: 4s;
  animation-delay: 36.5s;
  animation-fill-mode: forwards;
}

@keyframes rotate-last {
  0% {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }
  
  50%, 100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}